import React, { useContext } from "react"
import { Formik, Form, ErrorMessage } from "formik"
import FormErrors from "../../FormErrors"
import * as Yup from "yup"
import {
  FormControl,
  Box,
  TextField,
  Typography,
  Grid,
  useMediaQuery,
} from "@material-ui/core"
import Button from "../../Button"
import Paper from "../../Paper"
import { useTheme } from "@material-ui/styles"
import { useStyles } from "./style"
import AuthContext from "../../../context/auth/authContext"
import UserInitials from "../../Layout/UserInitials"

const Profile = () => {
  const classes = useStyles()

  const authContext = useContext(AuthContext)

  const { updateProfile, user, isLoading } = authContext
  const roleId = localStorage.getItem("role")
  console.log("The user object is: ", user, roleId)

  const initialValues = {
    firstName: user !== null ? user.firstName : "",
    lastName: user !== null ? user.lastName : "",
    email: user !== null ? user.email : "",
    phoneNumber: user !== null ? user.phoneNumber : "",
  }

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .test({
        name: "validator-custom-name",
        //eslint-disable-next-line object-shorthand
        test: function (value) {
          return /\s/.test(value)
            ? this.createError({
                message: "First Name should have no space",
                path: "firstName",
              })
            : true
        },
      })
      .required("First name is empty"),
    lastName: Yup.string()
      .test({
        name: "validator-custom-name",
        //eslint-disable-next-line object-shorthand
        test: function (value) {
          return /\s/.test(value)
            ? this.createError({
                message: "last Name should have no space",
                path: "lastName",
              })
            : true
        },
      })
      .required("Last name is empty"),
    email: Yup.string()
      .email("Invalid email format!")
      .required("Email is empty"),
    phoneNumber: Yup.string()
      .min(9, "Phone Number must not be less than 9 characters")
      .test({
        name: "validator-custom-name",
        //eslint-disable-next-line object-shorthand
        test: function (value) {
          return /\s/.test(value)
            ? this.createError({
                message: "Phone Number should have no space",
                path: "phoneNumber",
              })
            : true
        },
      })
      .test({
        name: "validator-custom-name",
        //eslint-disable-next-line object-shorthand
        test: function (value) {
          return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)
            ? this.createError({
                message: "Avoid special characters",
                path: "phoneNumber",
              })
            : true
        },
      })
      .matches(
        // /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Enter a valid phone number"
      )
      .required("Phone Number is empty"),
  })

  const onSubmit = async values => {
    const { firstName, lastName, email, phoneNumber } = values
    values = {
      id: user.id,
      roleId,
      firstName: firstName.charAt(0).toUpperCase() + firstName.slice(1),
      lastName: lastName.charAt(0).toUpperCase() + lastName.slice(1),
      userName: email,
      email,
      phoneNumber,
    }

    console.log("User submit values: ", values)
    updateProfile(values)
  }

  const theme = useTheme()
  const isMobileOrTab = useMediaQuery(theme.breakpoints.down("sm"))
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <Box>
      <Typography color="primary" variant="h6" gutterBottom>
        Profile
      </Typography>
      <Paper padding={25} borderRadius={1}>
        <Box width={isDesktop ? "90%" : "100%"}>
          <Typography variant="body2">Personal Information</Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, getFieldProps }) => (
              <Form noValidate autoComplete="off" className={classes.form}>
                <Grid container spacing={isMobileOrTab ? 0 : 3}>
                  <Grid item xs={12} sm={12} md={4}>
                    <Box align="center" marginBottom="1rem">
                      <UserInitials
                        width="5rem"
                        height="5rem"
                        bgColor="#F4F5F6"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <Grid container spacing={isMobileOrTab ? 0 : 3}>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className={classes.formControl}>
                          <Typography className={classes.label}>
                            First Name
                          </Typography>
                          <TextField
                            name="firstName"
                            id="firstName"
                            {...getFieldProps("firstName")}
                            variant="outlined"
                            size="small"
                          />
                          <ErrorMessage
                            name="firstName"
                            component={FormErrors}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className={classes.formControl}>
                          <Typography className={classes.label}>
                            Last Name
                          </Typography>
                          <TextField
                            name="lastName"
                            id="lastName"
                            {...getFieldProps("lastName")}
                            variant="outlined"
                            size="small"
                          />
                          <ErrorMessage
                            name="lastName"
                            component={FormErrors}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className={classes.formControl}>
                          <Typography className={classes.label}>
                            Email Address
                          </Typography>
                          <TextField
                            name="email"
                            id="email"
                            {...getFieldProps("email")}
                            variant="outlined"
                            size="small"
                            disabled
                          />
                          <ErrorMessage name="email" component={FormErrors} />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className={classes.formControl}>
                          <Typography className={classes.label}>
                            Phone Number
                          </Typography>
                          <TextField
                            name="phoneNumber"
                            id="phoneNumber"
                            {...getFieldProps("phoneNumber")}
                            variant="outlined"
                            size="small"
                          />
                          <ErrorMessage
                            name="phoneNumber"
                            component={FormErrors}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Box align="right" marginTop={isMobileOrTab ? 5 : 13}>
                  <FormControl>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isLoading}
                      loading={isLoading}
                    >
                      Save Changes
                    </Button>
                  </FormControl>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Paper>
    </Box>
  )
}

export default Profile
