import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: "2rem",
    width: "100%",
  },
  formControl: {
    minWidth: `100%`,
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0.5, 0),
    },
  },
  label: {
    fontSize: "14px",
    fontWeight: "400",
  },
}))

export { useStyles }
