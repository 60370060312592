import React from 'react'
import SEO from "../../components/seo"
import DashboardLayout from '../../components/DashboardLayout'
import Profile from '../../components/DashboardContent/Profile'

const profile = () => {
    return (
        <>
        <SEO title="Dashboard" />
        <DashboardLayout>
            <Profile />
        </DashboardLayout>
            
        </>
    )
}

export default profile
