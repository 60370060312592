import { Typography } from '@material-ui/core'
import React from 'react'

const FormErrors = props => {
    return (
        <Typography style={{color:"#FD2D55", fontSize:"13px"}}>
            {props.children}
        </Typography>
    )
}

export default FormErrors
